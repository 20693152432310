/* Layout styles */
.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Header styles */
.header {
  background-color: #1f2937;
  color: white;
  height: 64px;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 2rem;
  margin: 0 32px;
  max-width: 1300px;
}

.logo {
  flex: 0 0 auto;
}

.logo-image {
  height: 28px;
  width: auto;
}

.title {
  flex: 1;
  text-align: center;
  padding: 0px 250px 0px 450px;
}

.title h1 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #f97316;
  width: 200px;
}

.user-profile {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.user-greeting {
  font-size: 0.875rem;
  margin-right: 1rem;
  color: #ffffff;
}

.user-avatar {
  width: 32px;
  height: 32px;
  background-color: #f97316;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.logout-button {
  background-color: #ff6b6b;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: #ff4757;
}
/* Main content styles */
.main-content {
  flex: 1;
  padding: 2rem;
  background-color: #f3f4f6;
}

/* Main content styles */
.main-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: #f3f4f6;
}

/* Login page styles */
.container {
  width: 100%;
  max-width: 28rem;
}

.card {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  padding: 2rem;
}

.card-title {
  font-size: 1.875rem;
  font-weight: bold;
  color: #111827;
  margin-bottom: 1.5rem;
  text-align: center;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

/* Input styles */
.input-label {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  color: #374151;
  margin-bottom: 0.25rem;
}

.input-field {
  width: 100%;
  padding: 0.5rem 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  font-size: 0.875rem;
}

.input-field:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(249, 115, 22, 0.5);
  border-color: #f97316;
}

/* Button styles */
.btn-primary {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.375rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  font-size: 0.875rem;
  font-weight: 500;
  color: white;
  background-color: #f97316;
  transition: background-color 150ms ease-in-out;
}

.btn-primary:hover {
  background-color: #ea580c;
}

.btn-primary:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(249, 115, 22, 0.5);
}

.btn-link {
  font-size: 0.875rem;
  color: #f97316;
  text-decoration: none;
  transition: color 150ms ease-in-out;
}

.btn-link:hover {
  color: #ea580c;
}

.btn-link:focus {
  outline: none;
  text-decoration: underline;
}

/* Message styles */
.error-message {
  color: #ef4444;
  margin-bottom: 1rem;
  text-align: center;
}

.success-message {
  color: #10b981;
  margin-bottom: 1rem;
  text-align: center;
}

/* Notification styles */
.notification-icon {
  position: relative;
  margin-right: 20px;
  cursor: pointer;
}

.bell-icon {
  font-size: 24px;
  color: #ffffff;
}

.notification-count {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: #ff4d4f;
  color: #ffffff;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  min-width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-popup {
  display: none;
  position: absolute;
  top: 100%;
  right: -10px;
  width: 300px;
  max-height: 400px;
  background-color: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
}

.notification-icon:hover .notification-popup {
  display: block;
}

.notification-list {
  max-height: 300px;
  overflow-y: auto;
}

.notification-item {
  color: black;
  padding: 10px;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification-item:last-child {
  border-bottom: none;
}

.notification-item p {
  margin: 0;
  font-size: 14px;
  flex: 1;
  padding-right: 10px;
}

.notification-item p.bold {
  font-weight: bold;
}

.mark-read-button {
  background-color: #1890ff;
  color: #ffffff;
  border: none;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  white-space: nowrap;
}

.mark-read-button:hover {
  background-color: #40a9ff;
}

.no-notifications {
  padding: 10px;
  text-align: center;
  color: #999999;
}

/* Add these styles to your style.css */
.layout {
  display: flex;
  flex-direction: column;
}

.main-content {
  position: relative;
  overflow: hidden;
}

.scroll-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Ensure smooth scrolling */
.scroll-container {
  scroll-behavior: smooth;
}

/* Optional: Hide scrollbar for cleaner look */
.scroll-container::-webkit-scrollbar {
  display: none;
}

.scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
