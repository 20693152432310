.notification-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.notification-item {
    background-color: #ffffff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}

.notification-item:hover {
    background-color: #f9f9f9;
}

.notification-item.new {
    font-weight: bold;
    border-left: 4px solid #f97316;
}

.notification-item.read {
    font-weight: normal;
    color: #4b5563;
    border-left: 4px solid #9ca3af;
}

.notification-date {
    font-size: 12px;
    color: #6b7280;
    display: block;
    margin-top: 5px;
}

.date-filter input[type="date"] {
    border: 1px solid #d1d5db;
    border-radius: 4px;
    padding: 6px 10px;
    font-size: 14px;
}

.date-filter input[type="date"]:focus {
    outline: none;
    border-color: #f97316;
    box-shadow: 0 0 0 3px rgba(249, 115, 22, 0.1);
}

.mark-unread-button {
    background-color: #d4d4d4;
    color: #333333;
    border: none;
    padding: 4px 8px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
    white-space: nowrap;
  }
  
  .mark-unread-button:hover {
    background-color: #bdbdbd;
  }